import bgvid from '../RATHOLE BACKDROP.mp4';
function Bgvid() {
  return (
    <video className='bgvid' nocontrols autoPlay muted loop>
      <source src={bgvid} type='video/mp4'></source>
    </video>
  );
}

export default Bgvid;
