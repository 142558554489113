import Home from './Home';
import Tool from './Tool';
import About from './About';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import useLocalStorage from './components/useLocalStorage';
import { useMemo } from 'react';
import Gear from './Gear';
import gearIcon from './gear.svg';

function App() {
  // fetches browser data
  let investigatorInfo = JSON.parse(
    window.localStorage.getItem('investigatorInfo')
  );

  const defaultOptions = {
    caseName: 'default',
    caseNum: '',
    invName: '',
    contact: '',
    date: '',
    saved: false,
  };

  if (investigatorInfo == null) {
    investigatorInfo = defaultOptions;
  }

  const [options, setOptions] = useLocalStorage(
    'investigatorInfo',
    investigatorInfo
  );

  useMemo(() => {
    setOptions(options);
  }, [options]);

  return (
    <Router className='sticky'>
      <ul className='App-header sticky'>
        <li>
          <Link to='/'>Home</Link>
        </li>
        <li>
          <Link to='/tool'>Tool</Link>
        </li>
        <li>
          <Link to='/aboutus'>About Us</Link>
        </li>
        <li className='right'>
          <Link to='/gear'>
            <img className='icon' src={gearIcon} alt='gear' />
          </Link>
        </li>
      </ul>
      <Routes>
        <Route index exact path='/' element={<Home />}></Route>
        <Route
          exact
          path='/tool'
          element={<Tool options={options} defaultOptions={defaultOptions} />}
        ></Route>
        <Route exact path='/aboutus' element={<About />}></Route>
        <Route
          exact
          path='/gear'
          element={<Gear options={options} setOptions={setOptions} />}
        ></Route>
      </Routes>
    </Router>
  );
}
export default App;