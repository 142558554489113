import { Link } from 'react-router-dom';

function About() {
  return (
    <div className='about'>
      <div className='container'>
        <div>
          <span className='about tile'>Our Vision</span>
          <span className='paragraph'>
            To be a leading community for cybersecurity professionals and
            enthusiasts while eliminating ambiguity from the cyber world, as a
            non-profit project that aims to make cybersecurity knowledge and
            solutions available to all humans alike.{' '}
          </span>
        </div>
        <div>
          <span className='about tile'>Our Mission</span>
          <span className='paragraph'>
            To improve the quality of the cyberspace together as supporting
            community of talented individuals, and providing the corresponding
            solutions, education, and the right type of support to better the
            cybersecurity industry.
          </span>
        </div>
        <div>
          <span className='about tile'>Core Values</span>
          <span className='values'>
            <ul>
              <li>
                <strong>R</strong>esiliency
              </li>
              <li>
                <strong>A</strong>vailability
              </li>
              <li>
                <strong>T</strong>rust
              </li>
              <li>
                <strong>H</strong>umanism
              </li>
              <li>
                <strong>O</strong>ptimism
              </li>
              <li>
                <strong>L</strong>eadership
              </li>
              <li>
                <strong>E</strong>ngagement
              </li>
            </ul>
          </span>
        </div>
      </div>
      <Link to='/tool'>
        <button className='btn'>SCAN A WEBSITE</button>
      </Link>
      <span className='copy'>
        Copyright © 2023 RATHOLE - All Rights Reserved.
      </span>
    </div>
  );
}

export default About;
