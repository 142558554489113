import { useState, useEffect } from 'react';
import logo from './RATHOLE CHEESE.png';
import './App.css';
import Loading from './loading.gif';
import Data from './data.json';
import Bgvid from './components/bgvid';
import { Link } from 'react-router-dom';

function Tool({ options, defaultOptions }) {
  const [isLoaded, setLoaded] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [query, setQuery] = useState('');
  const [data, setData] = useState(Data);

  function textsubmit(e) {
    e.preventDefault();
    let selector = '';
    if (query !== undefined) {
      selector = document.querySelector('.url').value;
    }

    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setLoaded(true);
      setQuery(selector);
    }, 5000);
  }

  useEffect(() => {}, []);

  function checkInfo() {
    if (options.caseName === defaultOptions.caseName) {
      return (
        <Link to='/gear'>
          <button className='btn'>FILL INVESTIGATOR INFO</button>
        </Link>
      );
    } else {
      return (
        <button className='btn' type='submit'>
          Scan
        </button>
      );
    }
  }

  return (
    <div className='App'>
      <Bgvid />
      <header className='App-header'>
        <form
          className={'form ' + isLoaded + ' ' + isLoading}
          onSubmit={(e) => textsubmit(e)}
        >
          <label>
            <input
              type='text'
              className='url'
              name='url'
              placeholder='I N P U T  T A R G E T  U R L  . . .'
            />
          </label>
          {checkInfo()}
        </form>
        <div className={'loading ' + isLoading}>
          <img src={Loading} alt='Scanning' />
        </div>
        <div className={'invInfo ' + isLoaded}>
          <div className='outer'>
            <div className='inner'>
              <span className='label'>
                <strong>Case Name</strong>
              </span>
              <span>{options.caseName}</span>
              <span className='label'>
                <strong>Investigator's Name</strong>
              </span>
              <span>{options.invName}</span>
            </div>
            <div className='inner'>
              <span className='label'>
                <strong>Case Number</strong>
              </span>
              <span>{options.caseNum}</span>
              <span className='label'>
                <strong>Investigator's Contact</strong>
              </span>
              <span>{options.contact}</span>
              <span className='label'>
                <strong>Date</strong>
              </span>
              <span>{options.date}</span>
            </div>
          </div>
        </div>
        <div className={'message ' + isLoaded}>
          {data && query !== '' ? (
            <ol>
              {data.alerts
                .filter((alert) => {
                  if (query === '') {
                    return alert;
                  } else if (
                    alert.url.toLowerCase().includes(query.toLowerCase())
                  ) {
                    return alert;
                  }
                })
                .map((alert) => {
                  return (
                    <div key={alert.id}>
                      <table className='report'>
                        <tbody>
                          <li className='card'>
                            <h1>{alert.name}</h1>
                            <span className='alerttitle'>
                              <h2 className={'riskwcolor ' + alert.risk}>
                                {alert.risk}
                              </h2>
                            </span>

                            <tr>
                              <td className='label'>
                                <span>
                                  <strong>Description</strong>
                                </span>
                              </td>
                              <td>{alert.description}</td>
                            </tr>
                            <tr>
                              <td className='label'>
                                <span>
                                  <strong>Solution</strong>
                                </span>
                              </td>
                              <td>{alert.solution}</td>
                            </tr>
                            <tr>
                              <td className='label'>
                                <span>
                                  <strong>CWE ID</strong>
                                </span>
                              </td>
                              <td>{alert.cweid}</td>
                            </tr>
                            <tr>
                              <td className='label'>
                                <span>
                                  <strong>URL</strong>
                                </span>
                              </td>
                              <td>{alert.url}</td>
                            </tr>
                          </li>
                        </tbody>
                      </table>
                    </div>
                  );
                })}
            </ol>
          ) : (
            <span></span>
          )}
        </div>
      </header>
      <img src={logo} className='App-logo' alt='logo' />
    </div>
  );
}

export default Tool;
