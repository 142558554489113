import Bgvid from './components/bgvid';
import logo from './RATHOLE CHEESE.png';
import { Link } from 'react-router-dom';
import Typewriter from 'typewriter-effect';

function Home() {
  return (
    <div className='home'>
      <Bgvid />
      <div className='up'>
        <header className='title'>
          <Typewriter
           options={{
           strings: ['R A T H O L E'],
           wrapperClassName: 'Typewriter__RATHOLE',
           cursorClassName: 'Cursor__RATHOLE',
           autoStart: true,
           delay: 75,
           pauseFor: 999999999999
           }}
           />
          
          <span className='desc'>
          <Typewriter
            options={{
            strings: ['a web-based web application vulnerability scanning tool'],
            wrapperClassName: 'Typewriter__desc',
            cursorClassName: 'Cursor__desc',
            autoStart: true,
            loop: true,
            delay: 75,
            pauseFor: 2500,
            deleteSpeed: 8
            }}
          />
          </span>
        </header>
        <img src={logo} className='App-logo' alt='logo' />
      </div>

      <div className='down'>
        <header className='info'>
          <div>
            <span className='greeting'>Welcome, Detectives!</span>
            <span >
              We are pleased to introduce you to our Saudi made tool, RATHOLE,
              which is a passion project that is simple, accessible, and free
              for all users alike. Take a look around and learn more about our
              community goals!
            </span>
            <Link to='/aboutus'>
              <button className='btn'>LEARN MORE</button>
            </Link>
            <span className='copy'>
              Copyright © 2023 RATHOLE - All Rights Reserved.
            </span>
          </div>
        </header>
      </div>
    </div>
  );
}
export default Home;
