import { useState } from 'react';

function useLocalStorage(key = '', initVal = '') {
  const [state, setState] = useState(() => {
    try {
      const item = localStorage.getItem(key);
      return item ? JSON.parse(item) : initVal;
    } catch (error) {
      return initVal;
    }
  });
  const setLocalStorageState = (newState) => {
    try {
      const newStateValue =
        typeof newState === 'function' ? newState(state) : newState;
      setState(newStateValue);
      localStorage.setItem(key, JSON.stringify(newStateValue));
    } catch (error) {
      console.error(`Can't get ${key}`);
    }
  };
  return [state, setLocalStorageState];
}

export default useLocalStorage;
