import { useEffect, useState } from 'react';
import logo from './RATHOLE CHEESE.png';
import { Link, redirect } from 'react-router-dom';

function Gear({ setOptions, options }) {
  function update() {
    let currenOptions = {
      caseName: document.querySelector('.cName').value,
      caseNum: document.querySelector('.cNum').value,
      invName: document.querySelector('.iName').value,
      contact: document.querySelector('.con').value,
      date: document.querySelector('.dat').value,
      saved: true,
    };

    setOptions(currenOptions);
    redirect('https:rathole.app/tool');
  }

  useEffect(() => {}, []);

  return (
    <div className='gear'>
      <form onSubmit={update}>
        <ul>
          <li>
            <label>Case Name</label>
            <input
              className='cName'
              placeholder={options.caseName}
              type='text'
              onKeyUp={(e) => {
                setOptions({
                  ...options,
                  saved: false,
                });
              }}
            />
          </li>
          <li>
            <label>Case Number</label>
            <input
              className='cNum'
              placeholder={options.caseNum}
              type='text'
              onKeyUp={(e) => {
                setOptions({
                  ...options,
                  saved: false,
                });
              }}
            />
          </li>
          <li>
            <label>Investigator Name</label>
            <input
              className='iName'
              placeholder={options.invName}
              type='text'
              onKeyUp={(e) => {
                setOptions({
                  ...options,
                  saved: false,
                });
              }}
            />
          </li>
          <li>
            <label>Contact</label>
            <input
              className='con'
              placeholder={options.contact}
              type='text'
              onKeyUp={(e) => {
                setOptions({
                  ...options,
                  saved: false,
                });
              }}
            />
          </li>
          <li>
            <label>Date</label>
            <input
              className='dat'
              placeholder={options.date}
              type='text'
              onKeyUp={(e) => {
                setOptions({
                  ...options,
                  saved: false,
                });
              }}
            />
          </li>
          {!options.saved ? (
            <button className='btn' type='submit'>
              Save Info
            </button>
          ) : (
            <Link to='/tool'>
              <button className='btn'>Tool</button>
            </Link>
          )}
        </ul>
      </form>
      <img src={logo} className='settingspic' alt='logo' />
    </div>
  );
}

export default Gear;